import React , { useEffect } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@/components/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';



const Services = () => {
    const theme = useTheme();

        

    return (
        <div>

        </div>

    );



}

export default Services;